import React from "react";
import axios from 'axios';
import {useState, useRef} from "react";
import Swal from 'sweetalert2';
import ReCAPTCHA from "react-google-recaptcha"

function App() {

  const [userWallet, setUserWallet] = useState('');
  const [message, setMessage] = useState('');
  const [ipAddress, setIPAddress] = useState('')

  fetch("https://api.ipify.org/?format=json")
      .then(response => {
        return response.json();
      }, "jsonp")
      .then(res => {
        console.log(res.ip);
        setIPAddress(res.ip)
      })
      .catch(err => console.log(err))

  const handleSubmit = async (event) => {
    event.preventDefault();

    new Swal({
      title: 'Now loading',
      type: 'success',
      showConfirmButton: false
    })

    Swal.showLoading ()

    let token = captchaRef.current.getValue();

    try {
      const response = await axios.post('https://faucet-backend-kappa.vercel.app/api/submit', { userWallet, ipAddress, token });
      setMessage(response.data.message);

      new Swal({
        title: 'Finished!',
        type: 'success',
        showConfirmButton: false
      })
    } catch (error) {

      console.log(error.response.data.message)

      new Swal({
        title: error.response.data.message,
        type: 'error',
        showConfirmButton: false
      })
    }
  };

  const captchaRef = useRef(null);

  return (
      <>
        <header>
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container">
              <a className="navbar-brand" href="#">
                <img className="logo" src="./img/logo.jpeg" alt="" />
              </a>
              <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://docs.dailychain.org">
                      Docs
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <main>
          <section className="page-title text-center">
            <div className="container">
              <h1>
                TESTNET <span className="text-success fw-bold">FAUCET</span>
              </h1>
              <p>
                Obtain DLC Testnet tokens every 24 hours for seamless and confident
                development.
              </p>
            </div>
          </section>
          <section className="faucet">
            <div className="container text-center">
              <div className="row justify-content-center">
                <div className="col-md-6 col-12">
                  <div className="card">
                    <div className="card-header text-white-50 text-start position-relative">
                      DAILY FAUCET
                      <span className="green-circle" />
                    </div>
                    <div className="card-body text-start">
                      <form onSubmit={handleSubmit}>
                        <label htmlFor="wallet" className="mb-2 text-white-50">
                          Wallet Address
                        </label>
                        <input
                            id="wallet"
                            placeholder="Enter your Daily Chain Testnet address"
                            className="form-control mb-4"
                            type="text"
                            value={userWallet}
                            onChange={(e) => setUserWallet(e.target.value)}
                            required=""
                        />
                        <ReCAPTCHA ref={captchaRef} sitekey={'6Ld5mKEpAAAAAKwwW4PzXeCOHlx15E-boPPpE4wD'} />
                        <button className="btn send" type="submit">
                          Send 10 DLC
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
  );
}

export default App;
